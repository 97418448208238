import React from 'react';
import styled from 'styled-components';


const StyledLinkWrapper = styled.div`

  #gradient-horizontal-up {
    --color-stop-1: #7200a3;
    --color-stop-2: #ff00aa;
    --color-stop-3: #cc0099;
  }

    #gradient-horizontal-bottom {
      --color-stop-1: #002266;
      --color-stop-2: #85b4ff;
      --color-stop-3: #cc0099;
    }
  
  `;

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMaxYMid meet" viewBox="0 0 408 459">
     <path
     fill="#0a192f"
     d="M 16.7894,17.740197 C 119.0541,17.56391 221.31929,17.504018 323.58439,17.560522 L 325.02277,325.7733 C 223.1634,325.57566 121.30346,325.50023 19.443563,325.54698 Z"
     />
  <path
     fill="url(#gradient-horizontal-bottom)#1ec7eb "
     d="M 0.30616597,122.25846 1.0849241,341.50563 c 0,0 332.1131759,3.0379 338.4215859,0.435 0.95938,-26.56021 -0.39764,-48.53183 -0.56083,-70.73398 -0.31665,-43.09012 -2.20342,-80.55356 -21.1165,-108.60196 -19.41801,-28.79721 -51.13022,-57.62536 -114.22017,-55.35207 -0.5974,0.54565 8.94699,20.77895 7.14144,18.01605 103.59217,1.37835 115.9672,85.76919 111.55192,197.21292 L 20.983861,321.96271 17.441935,129.22555 C 14.548127,85.139267 6.0998755,108.97977 0.30616597,122.25846 Z"
     />
  <path
     fill="url(#gradient-horizontal-up) #f44ae7"
     d="M 340.90699,197.86726 339.674,0.85570494 c 0,0 -332.2981701,-1.71527672 -338.595214,0.91500116 C 0.23478678,28.334833 2.1823723,52.238752 2.4419616,74.439984 2.9457827,117.52832 6.0764395,153.69373 25.111177,181.65971 c 19.542915,28.71259 50.798092,57.69687 113.877573,55.14955 0.59502,-0.54824 -9.03717,-20.73989 -7.21963,-17.98486 C 28.171939,217.89603 15.430451,133.55975 19.361602,22.097893 l 301.317968,-0.789985 4.21009,189.789972 c 5.72793,14.64171 10.2894,9.70923 16.01733,-13.23062 z"
     />
  </svg>
);

export default IconLogo;
