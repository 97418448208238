import React from 'react';

const IconLoader = () => (
  <svg id="icon-loader" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="rect1"
      d="M190.032 87.987h28v75h-28v-75Z"
      fill="#f44ae7"

      strokeWidth="6"
    />
    <path
      className="rect2"
      d="M218.698 189.191v28h-75v-28h75Z"
      fill="#02d7f2"

      strokeWidth="6"
    />
    <path
      className="rect3"
      d="M88.032 142.987h28v75h-28v-75Z"
      fill="#f44ae7"

      strokeWidth="6"
    />
    <path
      className="rect4"
      d="M163.032 87.987v28h-75v-28h75Z"
      fill="#02d7f2"

      strokeWidth="6"
    />
  </svg>
);

// :root {
//   --stroke: #61cbf5;
//   --head: #aee8ff;
//   --fill: #61cbf5;
//   --textFill: #000;
//   --border: #61cbf5 0;
// }
// .logo-wrapper {
//   width: 100%;
//   height: 100%;
//   display: grid;
//   place-items: center;
// }
// .logo-wrapper svg {
//   fill: var(--fill);
// }
// .logo-wrapper svg path {
//   fill: var(--stroke);
// }
// #icon-loader path {
//   fill: var(--fill);
//   stroke: var(--stroke);
// }

export default IconLoader;
